@import "~@fontsource/lato/index.css";
@import "~@fontsource/lato/700.css";

html, body {
  min-height: 100%;
  min-width: calc(976px + 48px);
  height: 1px;
}

body {
  font-family: 'Lato', sans-serif!important;
  background: #f6f6f6!important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  height: 1px;
}
